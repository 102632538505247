import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';

// json 데이터
import FAQ from "./FAQ.json"


const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [displayedText, setDisplayedText] = useState('안녕하세요. 웃는 달팽이(smile snail)입니다. 보청기에 대해 궁금하신게 있나요?');
    const [isLoading, setIsLoading] = useState(false); // 답변 대기중 스피너 출력
    const [isLoadingVoice, setIsLoadingVoice] = useState(false); // 답변 대기중 스피너 출력

    // 스크롤 하단 고정
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // 모바일 100vh issue 해결을 위한 스크립트
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        setScreenSize();
    
        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
        window.removeEventListener('resize', handleResize);
        };
      }, []); // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행


    // 메세지
    const sendMessage = (messageToSend = text) => {

    // 입력 요소의 포커스 해제
    document.activeElement.blur();

    // 입력된 텍스트 또는 함수에 전달된 메시지를 사용
    const messageContent = messageToSend.trim();
    if (!messageContent) return;

    setMessages(prevMessages => [...prevMessages, { text: messageContent, sender: 'user' }]);
    setText('');
    setIsLoading(true); // 로딩 시작

    // 챗봇 응답 로직을 여기에 추가
    const answerInfo = {
        'user_query': messageContent,
        };
        console.log('user_query', messageContent);

        axios.post('https://camel-api.o2o.kr/run_dizzy', answerInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log('Response:', response);
            const answer = response.data["output"]
            console.log('answer:', answer);

            // 챗봇 답변 메시지 추가
            setMessages(prevMessages => [...prevMessages, { text: answer, sender: 'bot' }]);

            updateChipsWrapperContent(); // suggestion chip 변경

            setIsLoading(false); // 로딩 종료

        })
        .catch(error => {
            console.error('Error:', error);
            setIsLoading(false); // 로딩 종료
        });
    };

    // 메시지가 업데이트될 때마다 실행되는 useEffect
    useEffect(() => {
    // 마지막 메시지가 챗봇(bot)으로부터 온 것이라면 음성으로 읽어줍니다.
        if (messages.length > 0 && messages[messages.length - 1].sender === 'bot') {
            const msg = messages[messages.length - 1].text;
            console.log("bot msg : ",msg);
            speak(msg);
            }
        }, [messages]); // messages가 변경될 때마다 실행


    // 라운드 칩 클릭 시 메시지 전송
    const handleChipClick = (message) => {
        sendMessage(message);
    };


    // suggestion chip 내용 바꾸기 : id가 'chips-wrapper'인 요소의 내용을 새로운 데이터로 변환하는 함수
    const updateChipsWrapperContent = () => {

        // FAQ json에서 새로운 질문 3개 추출 
        let faq_list = FAQ.qa_list;
        console.log('faq_list', faq_list);
        let faq_extracted = faq_list.sort(()=> Math.random() - 0.5);
        faq_extracted = faq_extracted.slice(0,3);
        console.log('faq_extracted', faq_extracted);

        // 기존 데이터 지우기
        const chipsWrapper = document.getElementById('chips-wrapper');

        // 'chips-wrapper' 요소가 존재하면 내용을 새로운 데이터로 변환
        if (chipsWrapper) {
            // 기존 내용 삭제
            chipsWrapper.innerHTML = '';

            // 새로운 데이터로 요소 생성 및 추가
            faq_extracted.forEach((item) => {
                const newChip = document.createElement('div');
                newChip.className = 'rounded-chip';
                newChip.textContent = item;
                newChip.addEventListener('click', () => handleChipClick(item));
                chipsWrapper.appendChild(newChip);
            });
        }
    };

    // 현재 시간을 포맷팅하여 반환하는 함수
    const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        
        // 시간을 12시간 형식으로 변환
        const formattedHours = hours > 12 ? hours - 12 : hours;
        const period = hours >= 12 ? '오후' : '오전';
        
        // 0을 추가하여 두 자리 숫자로 만듦
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${period} ${formattedHours}:${formattedMinutes}`;
};


// 마이크 활성화
const startSpeechToText = () => {
    console.log('시작');
    setIsLoadingVoice(true); // 로딩 시작

    if ('webkitSpeechRecognition' in window) {
        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'ko-KR'; // 음성 인식 언어 설정
        recognition.start();

        // 음성 인식 결과 처리
        recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('');
            setText(transcript); // 음성 인식 결과를 입력 필드에 설정
            console.log("transcript >>", transcript);
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error', event);
        };

        // 음성 인식이 종료됐을 때 실행
        recognition.onend = () => {
            console.log('음성 인식 종료');
            setIsLoadingVoice(false); // 로딩 종료
        };

        // 7초 후에 음성 인식을 자동으로 중지
        setTimeout(() => {
            recognition.stop();
        }, 5000); // 5000 밀리초 = 5초
    } else {
        alert('브라우저가 음성 인식을 지원하지 않습니다.');
        setIsLoadingVoice(false); // 로딩 종료
    }
};

// 텍스트를 음성으로 변환하여 말해주는 함수
const speak = (text) => {
    if ('speechSynthesis' in window) {
        const speech = new SpeechSynthesisUtterance(text);
        speech.lang = 'ko-KR'; // 음성 언어 설정
        window.speechSynthesis.speak(speech);
        console.log('브라우저가 SpeechSynthesis를 지원.');
    } else {
        console.log('브라우저가 SpeechSynthesis를 지원하지 않습니다.');
    }
};



    



    return (
    <div className="App">
        <div className={`chat-container ${isLoading ? 'blurred' : ''}`}>
        {/* 채팅 화면 */}
            <div className='wrap'>
            <div className='container'>
            {/* 로딩 인디케이터 1*/}
            {isLoading && (
                <div className="loading-overlay">
                    <img src="img/spinner_01.gif" alt="로딩중" className="loading-spinner"/>
                </div>
            )}

            {/* 로딩 인디케이터 2*/}
            {isLoadingVoice && (
                <div className="loading-overlay">
                    <img src="img/spinner_02.gif" alt="로딩중" className="loading-spinner"/>
                </div>
            )}

            <div className='msger'>

            {/* 헤더 */}
            <header className='msger-header'>
                <div className='msger-header-title'>
                    <div className='header-logo-frame'>
                    <img src='img/audiso_logo.png' alt="Logo" style={{width:'120px',}}></img>
                    </div>
                </div>
            </header>

            {/* 채팅창 */}
            <main className='msger-chat msger-chat-frame'>
                <section className='msger-chat-prompt'>
                    <div className='scroll-prompt'>
                        <div className='greeting'>{displayedText}</div>
                    
                    {/* 메시지 및 로딩 인디케이터 표시 */}
                    {messages.map((message, index) => (
                        
                        <div className={`msg-bubble ${message.sender === 'user' ? 'msg-right' : 'msg-left'}`} key={index}>
                        {/* <div className="msg-icon"></div> */}
                        <div className='user-say-text'>{message.text}</div>
                        <div className="msg-time">{getCurrentTime()}</div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                    {isLoading && <div className="loading-indicator"></div>}
                    </div>
                </section>
                
                <form className='msger-form' onSubmit={(e) => {e.preventDefault(); sendMessage();}}>
                <div className='chips-wrapper' id='chips-wrapper'>
                    <div className="rounded-chip" onClick={() => handleChipClick('보청기 청소 방법')}>보청기 청소 방법</div>
                    <div className="rounded-chip" onClick={() => handleChipClick('보청기가 안들려요')}>보청기가 안들려요</div>
                    <div className="rounded-chip" onClick={() => handleChipClick('보청기 방향구별')}>보청기 방향구별</div>
                </div>
                <div className='msger-inputarea'>
                <img style={{ float: 'left', paddingTop: '12px', marginRight:'10px' }}src="img/home.svg"alt="home" onClick={() => window.location.reload()}></img>
                    <input
                    className="msger-input"
                    id="msger-input"
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder='무엇이든 물어보세요'
                    />
                    <img
                    style={{ width: '35px', paddingTop: '0px' }}
                    src="img/voice.svg"
                    alt="send"
                    onClick={startSpeechToText}>
                    </img>
                    <button className="send-btn" type="submit">
                    <img style={{ width: '35px', paddingTop: '0px' }} src="img/send.svg" alt="send"></img>
                    </button>
                </div>
                </form>
            </main>

        </div>
        </div>
        </div>
        </div>
    </div>
    );
    };

export default Chatbot;
